<template>
    <div class="spotify">
    <main>
        <section class="left-menu">
            <div class="top-menu container flex-cont-v ">
                <!-- ----------------------------- -->
                <div class="logo">
                    <div class="width-100 flex-cont flex-aic">
                        <img src="../../assets/spotify/img/logo.svg" class="logo-big" alt="">
                        <img src="../../assets/spotify/img/logo-small.svg" class="logo-small" alt="">
                    </div>
                </div>

                <div class="left-nav flex-cont flex-w flex-aic">
                    <div class="width-100 flex-cont flex-aic">
                        <img src="../../assets/spotify/img/home.svg" class="icon" alt="">
                        <span>Home</span>
                    </div>
                    <div class="width-100 flex-cont flex-aic">
                        <img src="../../assets/spotify/img/search.svg" class="icon" alt="">
                        <span>Cerca</span>
                    </div>
                    <div class="width-100 flex-cont flex-aic">
                        <img src="../../assets/spotify/img/libreria.svg" class="icon" alt="">
                        <span>La tua libreria</span>
                    </div>
                </div>
                <!-- ----------------------------- -->
                <div class="playlist">
                    <h3>Playlist</h3>
                    <div class="flex-cont flex-aic">
                        <div class=" flex-aic flex-jc-c  d-flex align-content-center align-items-center">
                            <div class="plus-sign flex-aic d-flex align-content-center align-items-center">
                                <i class="fa-solid fa-plus "></i>
                            </div>
                        </div>

                        <div class="flex-as-c">Crea playlist</div>
                    </div>
                    <ul>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>
                        <li>Nome playlist</li>

                    </ul>

                </div>
                <!-- ----------------------------- -->

            </div>
            <div class="bottom-menu container">

                <div class="profile left-nav">
                    <div class="width-100 flex-cont flex-aic">
                        <img src="../../assets/spotify/img/download.svg" class="icon" alt="">
                        <span>Installa App</span>
                    </div>
                    <div class="width-100 flex-cont flex-aic ">
                        <img src="../../assets/spotify/img/profile.svg" class="icon" alt="">
                        <span>John Doe</span>
                    </div>
                </div>

            </div>
        </section>

        <section class="main-space">

            <div class="  banner flex-cont flex-jc-fe flex-aic ">
                <div class="container flex-cont flex-jc-fe flex-aic flex-as-c">
                    <div class="upgrade flex-cont flex-aic button"><a href="#" class="ai">Effettua l'upgrade</a>
                    </div>
                </div>
            </div>

            <div class="categories-nav">
                <ul class="container flex-cont flex-w flex-aic flex-jc-sa">
                    <li>Podcast</li>
                    <li>Podcast</li>
                    <li>Podcast</li>
                    <li>Podcast</li>
                    <li>Podcast</li>
                    <li>Podcast</li>
                </ul>
            </div>

            <div class="recently-played">
                <div class="container">
                    <h2>Recently-played</h2>
                    <div class="flex-cont flex-w flex-ai-s justify-content-start list">
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>




                    </div>

                </div>

            </div>
            <div class="personalized-experience">
                <div class="container">
                    <h2>Creato per John Doe</h2>
                    <h4>Più ascolti, più accurati saranno i suggerimenti</h4>
                    <div class="flex-cont flex-w flex-ai-s flex-gap list">
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>



                    </div>

                </div>

            </div>
            <div class="best-artists">
                <div class="container">
                    <h2>Creato per John Doe</h2>
                    <h4>Più ascolti, più accurati saranno i suggerimenti</h4>
                    <div class="flex-cont flex-w flex-ai-s justify-content-start list">
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>
                        <div class="flex-cont-v flex-ac-c ms-2">
                            <div class="play-hover">
                                <img src="../../assets/spotify/img/metal_lifting.jpg" alt="">
                                <i class="fa-solid fa-play"></i>
                            </div>
                            <h3>Heavy Metal</h3>
                            <h4>Subtitle</h4>
                        </div>


                    </div>

                </div>

            </div>

            <div class="flex-cont">
            </div>

        </section>

    </main>

    <Footer>
        <div class="flex-cont flex-ac-c flex-sb w-100">





            <div class="track flex-cont flex-ac-c">
                <img src="../../assets/spotify/img/player_left.jpg" alt="">
                <div class="flex-cont-v flex-gap">
                    <div class="artist">Sinister</div>
                    <div class="title">Brano nome</div>
                </div>
                <div class="flex-cont-v flex-gap">
                    <div class="heart">
                        <i class="fa-solid fa-heart"></i>
                        <i class="fa-regular fa-heart"></i>
                    </div>
                    <i class="fa-regular fa-folder"></i>
                </div>
            </div>



            <div class="flex-cont-v flex-sb flex-gap flex-ac-c w-100">
                <div class="mediaplayer-station flex-cont justify-content-center align-content-center align-items-center ">
                    <div id="shuffle"><i class="fa-solid fa-shuffle"></i></div>
                    <div id="previous-song"><i class="fa-solid fa-backward-step"></i></div>
                    <div id="play-song"><i class="fa-solid fa-play"></i></div>
                    <div id="next-song"><i class="fa-solid fa-forward-step"></i></div>
                    <div id="repeat"><i class="fa-solid fa-arrow-rotate-right"></i></div>
                </div>
                <div class="mediaplayer flex-cont flex-ac-c flex-sb">
                    <div id="listening-time">0:25</div>
                    <div id="mediaplayer-bar">
                        <div id="listened-bar">
                            <!-- <div class="ball-cursor">
                                <i class="fa-solid fa-play">
                            </div> -->
                        </div>


                    </div>
                    <div id="missing-time">4:35</div>
                </div>
            </div>





            <div class="controller flex-cont flex-ai-s flex-sb d-none d-sm-flex">
                <i class="fa-solid fa-list"></i>
                <i class="fa-solid fa-desktop"></i>
                <i class="fa-solid fa-volume-high"></i>
                <div id="volume-bar">
                    <div id="actual-volume-bar">
                        <!-- <div class="ball-cursor">
                            <i class="fa-solid fa-play">
                        </div> -->
                    </div>
                </div>

            </div>

        </div>


    </Footer>
</div>
    
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss">

/*--------------------------------------RESET*/


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .spotify{
        font-family: 'Montserrat', serif;
        color: var(--color-6);
    }
}

:root {
    --color-1: #fff;
    --color-2: #000;
    --color-3: #141922;
    --color-4: #282828;
    --color-5: rgba(0, 0, 0, 0.6);
    --color-6: #A3A3A3;
    --color-7: #65D46E;
    --left-menu-width: 30rem;
    --banner-menu-height: 8rem;
    --footer-menu-height: 8rem;
}

.spotify{

ul li {
    list-style: none;
}

section {
    padding: 1.6rem 0;
}

h2 {
    color: var(--color-1);
    padding: 3.2rem 0 0 0;
}

h3 {
    color: var(--color-1);
}

i {
    margin-right: 1rem;
}


/*-----------------------------------Utilities*/

.container {
    width: calc(100% );
    margin: 0 auto;
    li{
        margin: 0 0.5rem ;
    }
}

.flex-cont {
    display: flex;
    flex-direction: row;
}

.flex-cont-v {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.flex-aic {
    align-items: stretch;
    align-content: center;
}

.flex-ai-s {
    align-items: stretch;
}

.flex-aie {
    align-content: flex-end;
}

.plus-sign {
    height: 6.4rem;
}

.flex-ac-c {
    align-content: center;
    align-items: center;
}

.flex-w {
    flex-wrap: wrap;
}

.flex-as-c {
    align-self: center;
}

.flex-grow {
    flex-grow: 1;
}

.flex-gap {
    // gap: 1.6rem;
}

.flex-sb {
    justify-content: space-between;
}

.flex-jc-fe {
    justify-content: flex-end;
}

.flex-jc-c {
    justify-content: center;
}

.flex-jc-sa {
    justify-content: space-around;
}

.button {
    padding: 1.6rem 3.2rem;
    border: .1rem solid var(--color-1);
    border-radius: 4.1rem;
}

.button a {
    text-decoration: none;
}

.width-100 {
    width: 100%;
}


/*-----------------------------------Banner*/

.banner {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - var(--left-menu-width));
    height: var(--banner-menu-height);
    background-color: var(--color-5);
}

.upgrade {
    text-transform: uppercase;
    height: 5rem;
    border-radius: 2.5rem;
    text-align: center;
    vertical-align: middle;
    margin-right: 1.6rem;
}


/*-----------------------------------Main*/

main {
    height: calc(100vh - var(--footer-menu-height));
    display: flex;
    min-height: var(--banner-menu-height);
}

.main-space {
    padding-top: var(--banner-menu-height);
    overflow: auto;
    position: relative;
    width: calc(100% - var(--left-menu-width));
    height: 100%;
    background-color: var(--color-3);
    font-size: 1.6rem;
    padding-right: 1.6rem;
}

.left-menu .flex-cont i {
    background-color: var(--color-6);
}

.left-menu {
    width: var(--left-menu-width);
    height: 100%;
    background-color: var(--color-2);
    overflow: hidden;
    font-size: 1.6rem;
}

.top-menu {
    height: calc( 100vh - var(--footer-menu-height) - 11.5rem);
    overflow: hidden;
    min-height: var(--banner-menu-height);
}

.bottom-menu {
    background-color: #000;
}

.logo-small {
    display: none;
}

.top-menu>div {
    margin-left: .6rem;
}

.playlist {
    line-height: 4rem;
    margin-top: 3.2rem;
}

.logo img {
    height: 5rem;
}

.left-nav {
    margin-top: 2rem;
}



.w-svg {
    fill: var(--color-1);
}

.left-menu .icon {
    width: 3rem;
    height: 3rem;
}

.fa-plus {
    color: #000;
    font-size: 1.6rem;
}

.left-menu span {
    align-self: center;
}

.playlist {
    flex-grow: 1;
}

.categories-nav li {
    padding: 2rem 0 0 0;
}

.recently-played div div div,
.personalized-experience div div div,
.best-artists div div div {
    padding: 0.5rem 0rem;
}

.list{
    gap: 1rem 2rem; /* row-gap column gap */
}

.categories-nav li {
    text-transform: uppercase;
}

.recently-played img,
.personalized-experience img,
.best-artists img {
    width: 11rem;
}

.main-space .fa-play {
    display: none;
}

.play-hover {
    position: relative;
}

.main-space .flex-cont .flex-cont-v:hover .fa-play {
    display: inline-block;
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4.8rem;
}

.main-space .flex-cont .flex-cont-v:hover img {
    opacity: 0.5;
    cursor: pointer;
}
.left-nav>div {
    border-left: .6rem solid transparent;
}

.left-nav>div:first-child {
    border-left: .6rem solid var(--color-7);
}

.categories-nav li:first-child {
    border-bottom: .6rem solid var(--color-7);
}

.left-nav>div:hover span {
    color: var(--color-1);
    cursor: pointer;
}

.categories-nav li:hover {
    color: var(--color-1);
    cursor: pointer;
}

.playlist li:hover,
.playlist div div:hover {
    color: var(--color-1);
    cursor: pointer;
}

.artist {
    color: var(--color-1);
}

.title {
    font-size: 1.2rem;
}


/*-----------------------------------Footer*/

footer {
    height: var(--footer-menu-height);
    background-color: var(--color-4);
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    font-size: 1.6rem;

    .container{
        width: calc( 100% - 5rem )!important;
        margin: 0 auto;
    }
}

.track img {
    width: 7rem;
}

.track,
.controller {
    width: 20vw;
    min-width: 17rem;
}

.track>*,
.controller>*,
.mediaplayer>*,
.mediaplayer-station>* {
    padding: 0 .8rem;
}

.mediaplayer-station {
    width: 40%;
    min-width: 5rem;
    margin: 0 auto;
}

.fa-heart.fa-solid {
    display: none;
}

.mediaplayer {
    width: 50%;
    min-width: 5rem;
    margin: 0 auto;
}

#mediaplayer-bar {
    height: .9rem;
    width: 100%;
    background-color: var(--color-6);
    position: relative;
    border-radius: .3rem .3rem;
}

#listened-bar {
    position: absolute;
    height: .9rem;
    width: 33%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: .3rem .3rem;
}

.controller {
    min-width: 12rem;
}

.controller {
    width: 20vw;
}

#volume-bar {
    height: .9rem;
    width: 100%;
    background-color: var(--color-6);
    position: relative;
    border-radius: .3rem .3rem;
}

#actual-volume-bar {
    position: absolute;
    height: .9rem;
    width: 77%;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: .3rem .3rem;
}

#actual-volume-bar:hover,
#listened-bar:hover {
    background-color: var(--color-7);
}

.ball-cursor {
    display: none;
}


/* .listened-bar:hover .ball-cursor,
.volume-bar:hover .ball-cursor{
    
    display: inline-block;
    transform: translate(-50%,-50%);

} */


/*------------------------------MEDIA-QUERY*/

@media all and (max-width: 110.1rem) {}

@media all and (max-width: 70.1rem) {}

@media all and (max-width: 60.1rem) {
     :root {
        --left-menu-width: 6rem;
        --banner-menu-height: 8rem;
        --footer-menu-height: 8rem;
    }
    .logo-big {
        display: none;
    }
    .logo-small {
        display: inline-block;
        width: 4rem;
    }
    .left-menu {
        width: 6rem;
    }
    .main-space {
        width: calc(100% - 6rem);
    }
    .left-menu span {
        display: none;
    }
    .playlist {
        display: none;
    }
    .banner {
        width: calc(100% - 6rem);
    }
    .list{
        gap: 0rem 0rem; /* row-gap column gap */
    }
    .recently-played img,
    .personalized-experience img,
    .best-artists img {
        width: 8rem;
    }
}
}

</style>