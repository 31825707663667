<template>
    <div class="playstation">
        <header>
            <div class="d-flex justify-content-end">
                <img src="../../assets/playstation/img/sony_logo.svg" alt="">
            </div>
        </header>

        <nav class="navbar navbar-expand-lg  ">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="../../assets/playstation/img/play_logo.svg" alt=""></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-lg-between" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Giochi</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>

                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Hardware</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Servizi</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Novità</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Fai acquisti</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">Supporto</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#">
                                        <img src="../../assets/playstation/img/play-menu-ico.png" alt="">
                                        <h5>Playstation 5</h5>
                                    </a>
                                </li>
                            </ul>
                        </li>


                    </ul>

                    <ul class="navbar-nav">
                        <li class="nav-item dropdown d-flex justify-content-center align-items-center">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">My Playstation</a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown d-flex justify-content-center align-items-center">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false"><button type="button"
                                    class="btn btn-dark ms_btn-dark">Accedi</button></a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown d-flex justify-content-center align-items-center">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false"><i
                                    class="fa-solid fa-magnifying-glass"></i></a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>


                        
                    </ul>
                </div>
            </div>
        </nav>


        <main>
            <section class="ms_container returnal p-5 dark-gray-section first-section">
                <div class="container container-md container-lg h-100 ">

                    <div class="row h-100 align-content-end flex-wrap ">


                        <div class="col-12 ">
                            <div class="row ">
                                <div class="col-6 col-md-5 col-lg-3 ">
                                    <img src="../../assets/playstation/img/returnal-hero-banner-logo.png" class="w-100" alt="returnal_logo">
                                </div>
                            </div>
                        </div>



                        <div class="col-12 ">
                            <div class="row ">
                                <div class="col-12 col-lg-5 ">
                                    <h2>Rompi il cerchio</h2>
                                    <div class="descr">Rialzati dopo ogni sconfitta e affronta una nuova sfida con ogni
                                        rinascita in questo
                                        esplosivo
                                        thriller
                                        fantascientifico, disponibile in esclusiva su PS5.</div>
                                    <div class="btn ms_btn-red">Acquista ora</div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <!-- --------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------- -->
            <!-- Ecco la PlayStation 5-- -->

            <section class="white_bgc p-5 white-section">
                <div class="container">
                    <div class="row">

                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/returnal-listing-thumb-01-ps5.jpg" class="w-100 rounded" alt="">
                        </div>

                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/ratchet-and-clank-rift-apart-keyart.jpg" class="w-100 rounded" alt="">
                        </div>

                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/returnal-listing-thumb-01-ps5.jpg" class="w-100 rounded" alt="">
                        </div>

                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/ratchet-and-clank-rift-apart-keyart.jpg" class="w-100 rounded" alt="">
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/returnal-listing-thumb-01-ps5.jpg" class="w-100 rounded" alt="">
                        </div>

                        <div class="col-6 col-sm-4 col-lg-2">
                            <img src="../../assets/playstation/img/ratchet-and-clank-rift-apart-keyart.jpg" class="w-100 rounded" alt="">
                        </div>


                        <div class="row py-5">
                            <div class="col-12 quote d-flex justify-content-center">PLAY HAS NO LIMITS</div>
                            <div class="col-12 d-flex justify-content-center">
                                <h2>Ecco la PlayStation 5</h2>
                            </div>
                            <div class="col-12 d-flex justify-content-center">Tuffati in nuove possibilità di gioco
                                completamente
                                inaspettate</div>
                        </div>
                    </div>



                    <div class="container">
                        <div class="row ">

                            <div class="col-12 col-md-6 d-flex align-items-center">
                                <div class="row">
                                    <div class="col-12">
                                        <h2>Console PlayStation 5</h2>
                                        <div class="descr">Sperimenta la nuova generazione di incredibili giochi
                                            PlayStation.</div>
                                        <div class="btn ms_btn-blue">Maggiori informazioni</div>


                                    </div>

                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <img src="../../assets/playstation/img/playstation-5-thumb.png" class="w-100" alt="Playstation 5">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 col-md-15 d-flex ">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../../assets/playstation/img/playstation-5-thumb.png" class="w-100" alt="">
                                        <div class="block">Console PS5</div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-4 col-md-15 d-flex opacity">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../../assets/playstation/img/joypad-thumb.png" class="w-100" alt="">
                                        <div class="block">Controller wireless DualSense™</div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-4 col-md-15 d-flex opacity">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../../assets/playstation/img/headset-thumb.png" class="w-100" alt="">
                                        <div class="block">Cuffie wireless con microfono PULSE 31</div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-4 col-md-15 d-flex opacity">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../../assets/playstation/img/remote-thumb.png" class="w-100" alt="">
                                        <div class="block">Telecomando Media</div>

                                    </div>
                                </div>

                            </div>

                            <div class="col-4 col-md-15 d-flex opacity">
                                <div class="row">
                                    <div class="col-12">
                                        <img src="../../assets/playstation/img/camera-thumb.jpg" class="w-100" alt="">
                                        <div class="block">Telecamera HD</div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <!-- It takes two -->

            <section class="ms_container it_takes_two p-5 dark-gray-section">
                <div class="container container-md container-lg h-100 ">

                    <div class="row h-100 align-content-end flex-wrap ">


                        <div class="col-12 ">
                            <div class="row ">
                                <div class="col-6 col-md-5 col-lg-3 ">
                                    <img src="../../assets/playstation/img/it-takes-two-logo.png" class="w-100" alt="returnal_logo">
                                </div>
                            </div>
                        </div>



                        <div class="col-12 ">
                            <div class="row ">
                                <div class="col-12 col-lg-5 ">
                                    <h2>Un'avventura cooperativa selvaggia ti attende</h2>
                                    <div class="descr">Trova un amico e partite insieme per lavventura più folle delle
                                        vostre vite prit
                                        Takes Two, un
                                        gioco
                                        d'avventura cooperativa piattaforme stravolge il suo genere.</div>
                                    <div class="btn ms_btn-red">Acquista ora</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Nuove uscite -->
            <section class="dark-gray-section p-5">
                <div class="container container-md container-lg h-100 ">
                    <div class="row h-100 align-content-end flex-wrap ">

                        <div class="col-12 ">
                            <h2>Nuove uscite</h2>
                            <h3>Giochi incredibili per PS4 e PS5 disponibili ora</h3>
                        </div>

                        <div class="row gy-3">
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/tony-hawks-pro-skater-1-2-store-art.jpg" class="w-100 rounded" alt="">
                                <span>Tony Hawk's™ Pro Skater™ 1+2</span>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-2">
                                <img src="../../assets/playstation/img/crash-bandicoot-4-its-about-time-store-art.jpg" class="w-100 rounded"
                                    alt="">
                                <span>Crash Bandicoot 4: It's About Time</span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <!-- Esplora playstation 4-->
            <section class="p-5 white-section">
                <div class="container container-md container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="col-12 d-flex justify-content-center">
                                <h2>Esplora PlayStation® 4</h2>
                            </div>
                            <div class="col-12 d-flex justify-content-center">Giochi incredibili e intrattenimento senza
                                fine. Non c'è
                                mai stato un momento migliore per giocare sulla
                                tua PS4.</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/ps4-slim-homepage-image-block.jpg" class="w-100" alt="">
                            <h2>PlayStation 4</h2>
                            <div class="descr">Giochi incredibili live su PS4, con TB di spazio di archiviazione.</div>
                            <div class="btn ms_btn-blue">Scopri di più</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/double11-202010-tw-psvr.jpg" class="w-100" alt="">
                            <h2>PlayStation VR</h2>
                            <div class="descr">Oltre 500 titoli ed esperienze di gioco. Scoprili tutti.</div>
                            <div class="btn ms_btn-blue">Scopri di più</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/dualshock-4-listing-thumb.jpg" class="w-100" alt="">
                            <h2>Accessori</h2>
                            <div class="descr">Migliora la tua esperienza PS4 con una gamma di accessori ufficiali.
                            </div>
                            <div class="btn ms_btn-blue">Scopri di più</div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Playstation Plus-->
            <section class="light-gray-section p-0">
                <div class="container container-md container-lg ">
                    <div class="row d-flex align-items-center ">
                        <div class="col-12 col-lg-6 p-5">
                            <div class="row">
                                <div class="col-6 col-lg-8">
                                    <img src="../../assets/playstation/img/ps-plus-logo.png" class="w-100" alt="">
                                </div>
                            </div>
                            <div class="descr">Migliora la tua esperienza PlayStation con l'accesso a multigiocatore
                                online, giochi
                                mensili, sconti esclusivi e altro ancora.</div>
                            <div class="btn ms_btn-gray">Esplora PlayStation Plus</div>
                        </div>
                        <div class="col-md-6 d-sm-none d-md-block d-md-none d-lg-block">
                            <img src="../../assets/playstation/img/ps-plus-april-monthly-games-featured-image-block.png" class="w-100" alt="">
                        </div>
                    </div>
                </div>
            </section>

            <!-- Nuovi sconti-->

            <section class="p-5 white-section">
                <div class="container container-md container-lg">
                    <div class="row">
                        <div class="col-12">
                            <div class="col-12 d-flex justify-content-center">
                                <h2>Nuovi sconti sul PlayStation Store</h2>
                            </div>
                            <div class="col-12 d-flex justify-content-center descr">Sconti pazzeschi, collezioni curate
                                e saldi
                                stagionali su PS5, PS4 e PS VR, tutti in un unico posto.</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/playstation-store-golden-week-sale-spotlight.jpg" class="w-100" alt="">
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/playstation-store-golden-week-sale-spotlight.jpg" class="w-100" alt="">
                        </div>
                        <div class="col-12 col-md-4">
                            <img src="../../assets/playstation/img/playstation-store-golden-week-sale-spotlight.jpg" class="w-100" alt="">
                        </div>
                    </div>
                </div>
            </section>

            <!-- PlayStation.Now-->

            <section class="dark-blue-section p-0">
                <div class="container container-md container-lg ">
                    <div class="row d-flex align-items-center ">
                        <div class="col-12 col-lg-6 p-5">
                            <div class="row">
                                <div class="col-6 col-lg-8">
                                    <img src="../../assets/playstation/img/ps-now-logo-two-column.png" class="w-100" alt="">
                                </div>
                            </div>

                            <div class="descr">Ottieni l'accesso immediato a una vastissima raccolta di oltre 800 giochi
                                per e PS4, PS3
                                e PS2 su PS4 o
                                PC 0 Windows, con nuovi titoli aggiunti ogni mese.</div>
                            <div class="btn ms_btn-white">Esplora PlayStation Now</div>
                        </div>
                        <div class="col-md-6 d-sm-none d-md-block d-md-none d-lg-block">
                            <img src="../../assets/playstation/img/ps-now-april-featured-image-block.png" class="w-100" alt="">
                        </div>
                    </div>
                </div>
            </section>

            <!-- Seguici-->
            <section class="p-5 white-section">
                <div class="container container-md container-lg">
                    <div class="row d-flex justify-content-center">
                        <div class="col-12 col-md-10 col-lg-6 ">
                            <div class="row ">
                                <div class="col-12 d-flex justify-content-center">
                                    <h2>Seguici sui social media</h2>
                                </div>

                                <div class="col-6 col-sm-3">
                                    <img src="../../assets/playstation/img/twitter.jpg" class="w-100" alt="twitter">
                                </div>
                                <div class="col-6 col-sm-3">
                                    <img src="../../assets/playstation/img/facebook.jpg" class="w-100" alt="facebook">
                                </div>
                                <div class="col-6 col-sm-3">
                                    <img src="../../assets/playstation/img/instagram.jpg" class="w-100" alt="instagram">
                                </div>
                                <div class="col-6 col-sm-3">
                                    <img src="../../assets/playstation/img/youtube.png" class="w-100" alt="youtube">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>


        <footer class="light-blue-section p-5">
            <div class="container container-md container-lg">
                <div class="row">
                    <div class="col-12">
                        <img src="../../assets/playstation/img/play_logo.svg" alt="">
                        <div>Country / Region: Italy</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <ul>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4">
                        <ul>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4">
                        <ul>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>
                            <li>Link footer n</li>

                        </ul>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-2">

                                <img src="../../assets/playstation/img/sie-logo.svg" class="w-100" alt="">

                            </div>
                        </div>


                        <div>Website ©2021 Sony Interactive Entertainment Europe Limited. Tutti i contenuti, nomi dei
                            giochi, nomi
                            commerciali e/o di abbigliamento, marchi registrati, grafica e immagini associati sono
                            marchi registrati e/o
                            materiale protetto da copyright dei rispettivi proprietari. Tutti diritti riservati.
                            Maggiori info</div>
                    </div>
                </div>
            </div>

        </footer>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    /* -----------------------------RESET */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .playstation {
        font-size: 1.6rem;
        font-family: Arial, Helvetica, sans-serif;
        }

    }
.playstation {

    .btn{
        font-size: 1.6rem;
        padding: 0.5rem 1.5rem;
    }




    header {
        background-color: #000;
        height: 4.8rem;
        padding: .8rem;
        position: fixed;
        z-index: 1;
        width: 100%;
        top: 0;
    }

    header img {
        height: 3.2rem;
    }

    section {
        padding: 1.6rem 0;
    }

    .ms_btn-dark {
        border-radius: 3.2rem;
    }

    .first-section {
        margin-top: 17rem;
    }

    #navbarDropdownMenuLink {
        color: #000;
    }

    section.ms_container {
        width: 100%;
        height: 52.5rem;
        background-size: cover;
        background-position: center;
    }

    section.returnal {
        background-image: url('../../assets/playstation/img/jumbo-bg.png');
    }

    .dark-gray-section {
        background-color: #1F1F1F;
        color: #FFFFFF;
    }

    .dark-blue-section {
        background-color: #161C3B;
        color: #F5F5F5;
    }

    .block {
        display: block;
    }

    .light-gray-section {
        background-color: #BEBCBB;
        color: #000;
    }

    .light-blue-section {
        background-color: #00439C;
        color: #FFFFFF;
    }

    .white-section {
        color: #727272;
    }

    .white-section h2 {
        color: #000;
    }


    .ms_btn-red {
        border-radius: 3.2rem;
        background-color: #CD3D0F;
        color: white;
    }

    .ms_btn-blue {
        border-radius: 3.2rem;
        background-color: #2D64E6;
        color: white;
    }

    .ms_btn-gray {
        border-radius: 3.2rem;
        background-color: #D9DADC;
        color: #2D64E6;
    }

    .ms_btn-white {
        border-radius: 3.2rem;
        background-color: #F5F5F5;
        color: #000;
    }

    .col-xs-15,
    .col-sm-15,
    .col-md-15,
    .col-lg-15 {
        position: relative;
        min-height: 0.2rem;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
    }

    .col-15 {
        width: 20%;
        float: left;
    }

    @media (min-width: 76.8rem) {
        .col-sm-15 {
            width: 20%;
            float: left;
        }
    }

    @media (min-width: 99.2rem) {
        .col-md-15 {
            width: 20%;
            float: left;
        }
    }

    @media (min-width: 120rem) {
        .col-lg-15 {
            width: 20%;
            float: left;
        }
    }

    .it_takes_two {
        background-image: url('../../assets/playstation/img/it-takes-two-hero-banner-desktop.jpg');
    }

    .quote {
        color: #0072CE;
    }

    h2 {
        margin-bottom: 4rem;

    }

    .descr {
        margin-bottom: 3.2rem;
    }

    .opacity {
        filter: opacity(0.5);
    }

    .opacity:hover {
        filter: opacity(1);
    }

    /* bootstrap */


    .navbar {
        position: fixed;
        z-index: 1;
        background-color: white;
        width: 100%;
        top: 4.8rem;
    }





    .dropdown,
    .dropdown-center,
    .dropend,
    .dropstart,
    .dropup,
    .dropup-center {
        position: static;
    }

    .dropdown-menu[data-bs-popper] {
        top: 96%;
        width: 100%;
    }

    .dropdown-menu.show {
        display: flex;
        justify-content: center;

    }
}
</style>