var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boolzapp"},[_vm._m(0),_c('div',{attrs:{"id":"left-container"}},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"h60px",attrs:{"id":"search-box"}},[_c('div',{staticClass:"input-group"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Cerca o inizia una nuova chat","aria-label":"Cerca o inizia una nuova chat","id":"search-input"},domProps:{"value":(_vm.searchString)},on:{"input":function($event){if($event.target.composing)return;_vm.searchString=$event.target.value}}})])]),_c('div',{attrs:{"id":"contacts-container"}},_vm._l((_vm.contacts),function(contact,index){return _c('div',{key:index,staticClass:"contact",class:{
                    'd-none': !_vm.includesLowerCase(_vm.getName(index), _vm.searchString),
                    'hover': _vm.highlightedIndex == index,
                },on:{"click":function($event){return _vm.changeIndex(index)},"mouseover":function($event){return _vm.higlightContact(index)}}},[_c('div',{staticClass:"centering-content avatar-medium"},[_c('img',{staticClass:"round-image",attrs:{"src":require(`../../assets/boolzapp/avatar${contact.avatar}.jpg`),"alt":"getName(index)"}})]),_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"inline"},[_c('div',[_vm._v(_vm._s(_vm.getName(index)))]),_c('div',{staticClass:"last-message"},[_vm._v(_vm._s(_vm.returnObject(index).date))])]),_c('div',[_c('div',[_vm._v(_vm._s(_vm.returnObject(index).message))])])])])}),0)]),_c('div',{attrs:{"id":"right-container"}},[_c('div',{attrs:{"id":"chat-contact"}},[_c('div',{staticClass:"h50px"},[_c('img',{attrs:{"src":require(`../../assets/boolzapp/avatar${_vm.contacts[_vm.activeIndex].avatar}.jpg`),"alt":_vm.contacts[_vm.activeIndex].name,"id":"main-avatar"}})]),_c('span',{staticClass:"flex-grow"},[_vm._v(_vm._s(_vm.contacts[_vm.activeIndex].name))]),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"flex-grow",attrs:{"id":"message-box"},on:{"click":function($event){return _vm.resetMenuMessage()}}},[_c('div',{attrs:{"id":"message-container"}},_vm._l((_vm.contacts[_vm.activeIndex].messages),function(message,messageIndex){return _c('div',{key:messageIndex,class:{
                    'message-sent': message.status === 'sent',
                    'message-received': message.status === 'received',
                    'd-none': message.status === 'deleted'
                }},[_c('div',{staticClass:"message inline"},[_c('span',[_vm._v(_vm._s(message.message))]),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showMessageMenu(messageIndex)},"mouseleave":function($event){return _vm.noMoreHover()}}},[_c('i',{staticClass:"fa-solid fa-chevron-down"})]),_c('div',{class:{
                            'd-none': _vm.messageMenuIndex != messageIndex,
                            'box': _vm.messageMenuIndex == messageIndex
                        }},[_c('div',[_vm._v("Message info")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteMessage(_vm.messageMenuIndex)}}},[_vm._v("Delete Message")])])]),_c('div',{staticClass:"message-time align-self-end"},[_vm._v(" "+_vm._s(message.date)+" ")])])}),0)]),_c('div',{attrs:{"id":"send-messages"}},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"input-text",attrs:{"type":"text","placeholder":"Scrivi un messaggio","aria-label":"Scrivi un messaggio"},domProps:{"value":(_vm.message)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendNewMessage(_vm.message)},"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"visual-message"},[_c('div',{staticClass:"visual-message_text"},[_c('span',{staticClass:"fi fi-gb"}),_vm._v(" This page is designed for viewing on desktop screens and may not be compatible with smaller devices. I recommend accessing this page from a computer for the best browsing experience, or using the zoom out function to adjust the display of the content. "),_c('br'),_c('br'),_vm._v(" ------------------------------ "),_c('br'),_c('br'),_c('span',{staticClass:"fi fi-it"}),_vm._v(" Questa pagina è progettata per essere visualizzata su schermi desktop e potrebbe non essere compatibile con dispositivi più piccoli. Ti suggerisco di accedere a questa pagina da un computer per un'esperienza di navigazione ottimale, oppure di utilizzare la funzione di zoom indietro per adattare la visualizzazione del contenuto. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h60px",attrs:{"id":"user"}},[_c('img',{staticClass:"avatar-small",attrs:{"src":require("../../assets/boolzapp/avatar_io.jpg"),"alt":"Sofia"}}),_c('span',{staticClass:"flex-grow"},[_vm._v("Sofia")]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-circle-notch"})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-message"})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app-notification"}},[_c('div',{staticClass:"bell-container centering-content"},[_c('i',{staticClass:"fa-solid fa-bell-slash"})]),_c('div',{},[_c('div',[_vm._v("Ricevi notifiche di nuovi messaggi")]),_c('a',{attrs:{"href":"#"}},[_vm._v("Attiva notifiche desktop")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-circle-notch"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-message"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon d-flex justify-content-center"},[_c('i',{staticClass:"fa-solid fa-face-smile"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon d-flex justify-content-center"},[_c('i',{staticClass:"fa-solid fa-microphone"})])
}]

export { render, staticRenderFns }