<template>
    <div class="dropbox">

        <div class="message"><div class="message__text">
            <span class="fi fi-gb"></span>
            This page is designed for viewing on desktop screens and may not be compatible with smaller devices. I recommend accessing this page from a computer for the best browsing experience, or using the zoom out function to adjust the display of the content.
            <br>
            <br>
            ------------------------------
            <br>
            <br>
            <span class="fi fi-it"></span>
            Questa pagina è progettata per essere visualizzata su schermi desktop e potrebbe non essere compatibile con dispositivi più piccoli. Ti suggerisco di accedere a questa pagina da un computer per un'esperienza di navigazione ottimale, oppure di utilizzare la funzione di zoom indietro per adattare la visualizzazione del contenuto.

        </div></div>

        <!-- <header class="flex-container-h flex-sb flex-align-center">
            
            <div class="header-sx">
                <a href=""><img src="../../assets/dropbox/img/logo-small.png" alt="Logo"></a>
                <a href="">Perchè Dropbox</a>
                <a href="">Funzioni</a>
                <a href="">Piani e Prezzi</a>
            </div>
            <div class="header-dx">
                <a href="">Supporto</a>
                <a href="">Contatta l'ufficio vendite</a>
                <a href="">Accedi</a>
                <a href="" class="blue-button">Inizia</a>
            </div>
        </header> -->

        <header class="flex-container-h flex-sb flex-align-center">
            <nav class="navbar navbar-expand-md navbar-light w-100">
                <a href=""><img src="../../assets/dropbox/img/logo-small.png" alt="Logo"></a>
                <div class="collapse navbar-collapse w-100 justify-content-between" id="navbarNav">
                    
                    <!-- <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled" href="#">Disabled</a>
                    </li>
                    </ul> -->
                    <div class="header-sx">
                        
                        <a href="">Perchè Dropbox</a>
                        <a href="">Funzioni</a>
                        <a href="">Piani e Prezzi</a>
                    </div>
                    <div class="header-dx">
                        <a href="">Supporto</a>
                        <a href="">Contatta l'ufficio vendite</a>
                        <a href="">Accedi</a>
                        <a href="" class="blue-button">Inizia</a>
                    </div>
                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </nav>
        </header>


        <main class="flex-container-v">
            <section class="intro">
                <div class="intro-container flex-container-v flex-align-center">
                    <h1>Tutto ciò che ti serve per il lavoro, in un unico posto</h1>
                    <div class="intro-content flex-container-h">
                        <div class="intro-text flex-container-v flex-sb">
                            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident aliquid eaque iusto
                                eum
                                in sapiente omnis tempore voluptate velit enim suscipit repellat fugiat accusantium,
                                pariatur deserunt veritatis ipsam fuga reiciendis?</div>
                            <div><a href="" class="blue-button">Inizia la prova gratuita</a></div>
                            <div>Annulla in qualsiasi momento</div>
                            <div class="arrow"><i class="fa-solid fa-arrow-down"></i></div>
                        </div>
                        <div class="intro-img">
                            <img src="../../assets/dropbox/img/jumbo.png" alt="">
                        </div>
                    </div>
                </div>
            </section>

            <section class="business-opportunity container">


                <div class="find-your-plan">
                    <h2>Trova il piano business che fa per te</h2>

                    <div class="find-your-plan-fee flex-container-h">
                        <div class="monthly-fee"> <span></span> Fatturazione mensile</div>
                        <div class="annual-fee"> <span></span> Fatturazione annuale</div>
                    </div>

                </div>


                <div class="business-plan-list flex-container-h flex-wrap">


                    <div class="business-plan-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="business-plan-title flex-container-h flex-align-center flex-center list-icon user">
                            Singolo</div>
                        <div class="business-plan-name box-container">
                            <h3>Professional</h3>
                        </div>
                        <div class="business-plan-fee box-container">&euro; 12.00/mese</div>
                        <ul class="specification box-container">
                            <li class="list-icon user-quantity user"> 1 utente</li>
                            <li class="list-icon memory-storage memory"> 5 TB di spazio di archiviazione sicuro</li>
                            <li class="list-icon added-functions functions"> Funzioni di produttività premium e
                                conliisione file semplice e
                                sicura</li>
                        </ul>
                        <div class="day-of-trial box-container center"> <a href="" class="blue-button center">Prova
                                gratis
                                per 30
                                giorni</a> </div>
                        <div class="purchase box-container">Oppure <a href="#">acquista ora</a></div>
                        <div class="strong box-container">Punti salienti del piano</div>
                        <ul class="highlights box-container">
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                            <li class="list-icon check">punti salienti del piano</li>
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                        </ul>
                    </div>


                    <div class="business-plan-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="business-plan-title flex-container-h flex-align-center flex-center list-icon user">
                            Singolo</div>
                        <div class="business-plan-name box-container">
                            <h3>Professional</h3>
                        </div>
                        <div class="business-plan-fee box-container">&euro; 12.00/mese</div>
                        <ul class="specification box-container">
                            <li class="list-icon user-quantity user"> 1 utente</li>
                            <li class="list-icon memory-storage memory"> 5 TB di spazio di archiviazione sicuro</li>
                            <li class="list-icon added-functions functions"> Funzioni di produttività premium e
                                conliisione file semplice e
                                sicura</li>
                        </ul>
                        <div class="day-of-trial box-container center"> <a href="" class="blue-button center">Prova
                                gratis
                                per 30
                                giorni</a> </div>
                        <div class="purchase box-container">Oppure <a href="#">acquista ora</a></div>
                        <div class="strong box-container">Punti salienti del piano</div>
                        <ul class="highlights box-container">
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                            <li class="list-icon check">punti salienti del piano</li>
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                        </ul>
                    </div>

                    <div class="business-plan-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="business-plan-title flex-container-h flex-align-center flex-center list-icon user">
                            Singolo</div>
                        <div class="business-plan-name box-container">
                            <h3>Professional</h3>
                        </div>
                        <div class="business-plan-fee box-container">&euro; 12.00/mese</div>
                        <ul class="specification box-container">
                            <li class="list-icon user-quantity user"> 1 utente</li>
                            <li class="list-icon memory-storage memory"> 5 TB di spazio di archiviazione sicuro</li>
                            <li class="list-icon added-functions functions"> Funzioni di produttività premium e
                                conliisione file semplice e
                                sicura</li>
                        </ul>
                        <div class="day-of-trial box-container center"> <a href="" class="blue-button center">Prova
                                gratis
                                per 30
                                giorni</a> </div>
                        <div class="purchase box-container">Oppure <a href="#">acquista ora</a></div>
                        <div class="strong box-container">Punti salienti del piano</div>
                        <ul class="highlights box-container">
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                            <li class="list-icon check">punti salienti del piano</li>
                            <li class="list-icon check">Invia file fino a 100 GB con Dropbox Transfert</li>
                            <li class="list-icon check">Cartelle offline su dispositivi mobili</li>
                        </ul>
                    </div>



                    <div class="business-plan-vertical-box flex-container-v flex-sb columns-div-x1">
                        <div class="business-plan-title list-icon user">Team</div>
                        <div class="content flex-container-h">
                            <div class="columns-div-2on3 flex-container-v flex-grow-2">
                                <div class="business-plan-name">
                                    <h3>Enterprise</h3>
                                </div>
                                <div class="strong">Contatta l'ufficio vendite per i prezzi</div>
                                <div class="Enterprise-informations">Soluzioni personalizzate e supporto su misura
                                    per aiutare i professionisti IT nella gestione su qualsiasi scala</div>
                            </div>
                            <div class="day-of-trial flex-container-v columns-div-1on3 flex-start align">
                                <a href="" class="blue-button">Prova gratis per 30 giorni</a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="drop-box-partner container flex-container-h">
                <div class="columns-div-2on3">
                    <img src="../../assets/dropbox/img/partner.png" alt="">
                </div>
                <div class="flex-container-v columns-div-1on3">
                    <h3>Entra in contatto con un partner Dropbox</h3>
                    <div> Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam magni neque ut natus, sed
                        deserunt corrupti ad soluta commodi recusandae dicta, quia nostrum? Odio enim asperiores
                        quibusdam
                        quis incidunt dolore. </div>
                    <div> <a href="" class="white-button">Trova un partner</a> </div>
                </div>
            </section>

            <section class="operations container">

                <h2>Esegui più operazioni con Dropbox Business</h2>

                <div class="operations-list flex-container-h flex-wrap">

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>

                    <div class="operations-vertical-box flex-container-v flex-sb columns-div-x3">
                        <div class="operations-img ">
                            <img src="../../assets/dropbox/img/business-feature-multi-device.png" alt="">
                        </div>
                        <h3 class="operations-title">Passa di meno da uno strumento all'altro</h3>
                        <div class="operations-description ">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Rem id iusto officia in blanditiis officiis ex tempora veniam quas illo corrupti, provident
                            pariatur, neque sed doloremque magni nulla optio perspiciatis!</div>
                    </div>
                </div>



            </section>

            <section class="community container">
                <h2>Più di 500.000 team utilizzano Dropbox Business</h2>
                <a href="#">Scopri di più sui nostri clienti</a>

            </section>

            <section class="customer row-gap-0">
                <div class="flex-container-h flex-wrap customers-box row-gap-0 ">
                    <div class="flex-container-h flex-wrap side-customers row-gap-0">
                        <div class="little-box box-1"><img src="../../assets/dropbox/img/designit.svg" alt=""></div>
                        <div class="little-box box-2"><img src="../../assets/dropbox/img/arizona_state_university.svg"
                                alt="">
                        </div>
                        <div class="little-box box-3"><img src="../../assets/dropbox/img/university_of_florida.svg"
                                alt="">
                        </div>
                        <div class="little-box box-4"><img src="../../assets/dropbox/img/topps.svg" alt=""></div>
                    </div>
                    <div class="central-customer">
                        <div class="container-80">
                            <div class="central-customer-logo">
                                <img src="../../assets/dropbox/img/designit.svg" alt="">
                            </div>
                            <div class="line">
                                <hr>
                            </div>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo sunt eligendi saepe
                                expedita
                                ipsam doloribus, eum quae? Obcaecati fugiat illo praesentium porro minima aliquid.
                                Quidem
                                hic totam quia ratione exercitationem.</p>
                            <div>Morten Thomsen, Global IT lead</div>

                        </div>
                    </div>
                    <div class="flex-container-h flex-wrap side-customers row-gap-0">
                        <div class="little-box box-5"><img src="../../assets/dropbox/img/world_bicycle_relief.svg" alt="">
                        </div>
                        <div class="little-box box-6"><img src="../../assets/dropbox/img/hearst_corp.svg" alt=""></div>
                        <div class="little-box box-7"><img src="../../assets/dropbox/img/lonely_planet.svg" alt=""></div>
                        <div class="little-box box-8"><img src="../../assets/dropbox/img/clear_channel.svg" alt=""></div>
                    </div>
                </div>
                <div class="banner-orizzontale row-gap-0 flex-container-h flex-align-center ">
                    <div class="container flex-container-h flex-align-center">
                        <div class="high-attention columns-div-2on3">
                            <h2>Porta Dropbox Business nella tua azienda.</h2>
                        </div>
                        <div class="columns-div-1on3"> <a href="#" class="white-button">inizia la tua prova gratuita</a>
                        </div>
                    </div>
                </div>


            </section>

            <section class="faq container flex-container-v ">
                <h3>Domande frequenti</h3>
                <div class="flex-container-v faq-box row-gap-0">
                    <div class="faq-question flex-container-h flex-align-center strong flex-sb">
                        <div>Quale è la differenza tra Dropbox Vusiness Standard e Dropbox Business Advanced?</div>
                        <div class="arrow-expand"><i class="fa-solid fa-angle-down"></i></div>
                    </div>
                    <div class="faq-question flex-container-h flex-align-center strong flex-sb">
                        <div>Quale è la differenza tra Dropbox Vusiness Standard e Dropbox Business Advanced?</div>
                        <div class="arrow-expand"><i class="fa-solid fa-angle-down"></i></div>
                    </div>
                    <div class="faq-question flex-container-h flex-align-center strong flex-sb">
                        <div>Quale è la differenza tra Dropbox Vusiness Standard e Dropbox Business Advanced?</div>
                        <div class="arrow-expand"><i class="fa-solid fa-angle-down"></i></div>
                    </div>

                </div>

            </section>



        </main>

        <footer>
            <div class="footer-list container flex-container-h">
                <div class="link-list flex-container-v columns-div-x5">
                    <h4>Dropbox</h4>
                    <ul>
                        <li>Applicazione desktop</li>
                        <li>Perchè Dropbox</li>
                        <li>Piani</li>
                        <li>Sicurezza</li>
                        <li>Funzioni</li>
                    </ul>
                </div>
                <div class="link-list flex-container-v columns-div-x5">
                    <h4>Dropbox</h4>
                    <ul>
                        <li>Applicazione desktop</li>
                        <li>Perchè Dropbox</li>
                        <li>Piani</li>
                        <li>Sicurezza</li>
                        <li>Funzioni</li>
                    </ul>
                </div>
                <div class="link-list flex-container-v columns-div-x5">
                    <h4>Dropbox</h4>
                    <ul>
                        <li>Applicazione desktop</li>
                        <li>Perchè Dropbox</li>
                        <li>Piani</li>
                        <li>Sicurezza</li>
                        <li>Funzioni</li>
                    </ul>
                </div>
                <div class="link-list flex-container-v columns-div-x5">
                    <h4>Dropbox</h4>
                    <ul>
                        <li>Applicazione desktop</li>
                        <li>Perchè Dropbox</li>
                        <li>Piani</li>
                        <li>Sicurezza</li>
                        <li>Funzioni</li>
                    </ul>
                </div>
                <div class="link-list flex-container-v columns-div-x5">
                    <h4>Dropbox</h4>
                    <ul>
                        <li>Applicazione desktop</li>
                        <li>Perchè Dropbox</li>
                        <li>Piani</li>
                        <li>Sicurezza</li>
                        <li>Funzioni</li>
                    </ul>
                </div>

            </div>
            <div class="Footer-lingua container">
                <div class="container">
                    <hr>
                </div>
                <div class="lingua container">
                    <i class="fa-solid fa-earth-americas"></i> Italiano <i class="fa-solid fa-sort-up"></i>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
/*-------------------------RESET*/

@import "../../sass/dropbox/app.scss";

* {
    margin: 0;
    padding: 0%;
    box-sizing: border-box;



    .dropbox {
        font-family: 'Roboto Flex', sans-serif;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 200;

        .message{
            display: none;
            @include respond(tab-port) {
                display: inline-block;
                width: 100%;
                height: 100vh;
                background: rgba(0, 0, 0, 0.9);
                position: fixed;
                z-index: 100;
                color: white;
            }
            &__text{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-size: 2.5rem;

                @include respond(tab-port) { 
                    font-size: 2rem;                
                }
                @include respond(phone) {
                    font-size: 1.5rem;
                }


                }
            }
        }
    }


.dropbox {

    a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.6);
        font-size: 1.2rem;
    }

    .header-sx, .header-dx{
        font-size: 1.2rem;
        &>*{
            color: rgba(0, 0, 0, 0.6);
        }

        a{
            font-size: 1rem;
        }
    }
    .business-opportunity{
        font-size: 1.2rem;
    }



    h1,
    h2,
    h3 {
        color: black;
    }

    h1 {
        font-size: 5.6em;
        margin: 1rem auto;
        font-weight: 700;
    }

    h2 {
        font-size: 2em;
        font-weight: 600;
    }

    h3 {
        font-size: 2em;
        font-weight: 400;
    }

    .container {
        width: 100rem;
        margin: 0 auto;
    }

    .strong {
        font-weight: bold;
    }

    li {
        list-style-type: none;
        padding-bottom: 1rem;
    }

    /*-------------------------FLEX-CLASS*/

    .flex-container-h {
        display: flex;
        row-gap: 2rem
    }

    .flex-container-v {
        display: flex;
        flex-direction: column;
        row-gap: 2rem
    }

    .flex-sb {
        justify-content: space-between;
    }

    .flex-align-center {
        align-content: center;
        align-items: center;
    }

    .flex-start {
        justify-content: flex-start;
    }

    .flex-center {
        justify-content: center;
    }

    .flex-end {
        justify-content: flex-end;
    }

    .align {
        align-items: flex-end;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .vertical-center {
        vertical-align: middle;
    }

    .flex-grow-2 {
        flex-grow: 2;
    }


    /*-------------------------HEADER*/

    header {
        padding: 0 2rem;
        height: 6.5rem;
        background-color: #FFFFFF;
        position: fixed;
        z-index: 1;
        width: 100%;
        box-shadow: .1rem black;
    }

    header a {
        margin: 1rem;
    }

    .blue-button {
        background-color: #275FF6;
        display: inline;
        border-radius: .1rem;
        color: #FFFFFF;
        padding: .5rem 1rem;
    }

    .white-button {
        background-color: #FFFFFF;
        display: inline;
        border: .1rem solid rgba(0, 0, 0, 0.3);
        border-radius: .1rem;
        padding: .5rem 1rem;
        color: rgba(0, 0, 0, 0.6);
    }

    /*-------------------------MAIN*/
    /*INTRO*/
    section {
        padding-top: 8rem;
    }

    section:last-child {
        padding-bottom: 8rem;
    }

    .intro {
        background-color: #B9CFE5;
    }

    .intro-container {
        width: 100rem;
        margin: 0 auto;
    }

    .intro-content {
        width: 100%;
    }

    .intro-text {
        font-size: 1.2rem;
        height: 25rem;
        width: 30%;
    }

    .intro-img {
        width: 70%;
        position: relative;
    }

    .intro-img img {
        position: absolute;
        width: 100%;
    }
    .container-80{
        font-size: 1.2rem;
    }

    .arrow i {
        font-size: 3.6rem;
        color: black;
        padding-bottom: 2rem;
    }

    /*BUSINESS-OPPORTUNITY*/
    .business-opportunity {
        margin-top: 8rem;
    }

    .find-your-plan-fee {}

    .find-your-plan-fee div {
        margin: 1rem 1rem;
    }

    .monthly-fee span {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: .1rem solid rgba(0, 0, 0, 0.3);
        display: inline-block;
        vertical-align: middle;
    }

    .annual-fee span {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: .1rem solid rgba(0, 0, 0, 0.3);
        display: inline-block;
        vertical-align: middle;

    }

    .monthly-fee:hover {
        cursor: pointer;

    }

    .operations-description{
        font-size: 1.2rem;
    }

    .annual-fee:hover {
        cursor: pointer;

    }

    .monthly-fee:hover span {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: .6rem solid blue;
        display: inline-block;
        cursor: pointer;

    }

    .annual-fee:hover span {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: .6rem solid blue;
        display: inline-block;
        cursor: pointer;
    }

    .business-plan-vertical-box {
        border: .1rem solid rgba(0, 0, 0, 0.3);
    }

    .box-container {
        width: calc(100% - 2rem);
        margin: 0 auto;
    }

    .container-80 {
        width: 80%;
        margin: 10% auto;
    }

    .business-plan-title {
        border-bottom: .1rem solid rgba(0, 0, 0, 0.3);
        text-align: center;
        vertical-align: middle;
        padding: 0.6rem 0;
    }


    .business-plan-vertical-box div {
        flex-grow: 1;
    }

    .day-of-trial {
        margin: 0 auto;
    }

    .list-icon::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-right: .5rem;
    }

    .list-icon.user::before {
        content: '\f007 ';
    }

    .list-icon.memory::before {
        content: '\f07b ';
    }

    .list-icon.functions::before {
        content: '\f1e0 ';
    }

    .list-icon.check::before {
        content: '\f00c ';
    }

    .business-plan-horizontal-box {
        margin: 2rem 1rem;
        border: .1rem solid rgba(0, 0, 0, 0.3);
    }

    .center {
        margin: auto;
    }

    .columns-div-x3 {
        width: calc(100% / 3 - 1rem * 2);
        margin: 0 1rem;
    }

    .columns-div-x1 {
        width: calc(100% / 1 - 1rem * 2);
        margin: 0 1rem;
    }

    .columns-div-x2 {
        width: calc(100% / 2 - 1rem * 2);
        margin: 0 1rem;
    }

    .columns-div-x5 {
        width: calc(100% / 5 - 2rem * 2);
        margin: 0 1rem;
    }

    .columns-div-2on3 {
        width: calc((100% / 3 - 2rem * 2) * 2);
        margin: 0 1rem;
        font-size: 1.2rem;

    }

    .columns-div-1on3 {
        width: calc((100% / 3 - 2rem * 2) * 1);
        margin: 0 1rem;
        font-size: 1.2rem;


    }

    .business-plan-vertical-box :last-child {
        padding-bottom: 1rem;
    }

    .drop-box-partner img {
        width: 100%;
    }

    .operations-vertical-box div {
        flex-grow: 1;
    }

    .flex-grow-0 {
        flex-grow: 0;
    }

    .operations-img img {
        width: 100%;
    }

    .little-box {
        width: calc(100% / 2);
        height: calc(100% / 2);
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .little-box:hover {
        border: 1rem solid rgba(255, 255, 255, 0.6);
    }

    .little-box img {

        width: calc(100vw / 12);
        filter: invert(100%)
    }

    .central-customer,
    .side-customers {
        width: calc(100% / 3);
        height: calc(100vw / 3);
        background-color: #F5F5F5;
    }

    .central-customer-logo img {
        width: 50%;
    }

    // .customer {
    //     row-gap:;
    // }

    // .customers-box {
    //     row-gap:;
    // }

    // .side-customers {
    //     row-gap:;
    // }

    .box-1 {
        background-color: #A2D39B;
    }

    .box-2 {
        background-color: #610A2B;
    }

    .box-3 {
        background-color: #0193CE;
    }

    .box-4 {
        background-color: #FF261C;
    }

    .box-5 {
        background-color: #D51E1E;
    }

    .box-6 {
        background-color: #B4D0E7;
    }

    .box-7 {
        background-color: #0D2481;
    }

    .box-8 {
        background-color: #FFD830;
    }

    .banner-orizzontale {
        background-color: #B4D0E7;
        width: 100%;
        height: calc(100vw / 3 * 0.75);
        // row-gap:;
    }

    .row-gap-0 {
        // row-gap:;
    }

    .faq-question {
        height: calc(100vw / 18);
        border-bottom: .1rem solid rgba(0, 0, 0, 0.3);
        font-size: 1.2rem;
        font-weight: 200;
    }

    .faq-box .faq-question:first-child {
        border-top: .1rem solid rgba(0, 0, 0, 0.3);
    }

    .high-attention h2 {
        color: #58132B;
    }

    .arrow-expand {}

    /*-------------------------FOOTER*/
    footer {
        padding: 5rem 0;
        background-color: black;
    }

    footer li,
    footer div,
    footer h4 {
        color: white;

    }

    .footer-list {
        padding: 2rem 0;
    }

    .Footer-lingua {
        margin: 2rem auto;

    }

    .lingua {
        margin: 2rem auto;
    }

    .lingua i {
        color: #758185;
    }
}
</style>